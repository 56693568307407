<template>
  <div v-if="items && items.length">
    <b-table
      v-if="!isMobile"
      id="tbl-privacy-log"
      striped
      hover
      small
      class="datatable table-responsive"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #cell(date)="data">
        <span v-shtml="data.value" />
      </template>
      <template #cell(staffUser)="{ item }">
        <span v-if="item.staffUser">
          <span v-if="item.staffUser.role !== ''" class="font-weight-bold">[{{ item.staffUser.role }}] </span>
          <span class="">{{ item.staffUser.email }}</span>
        </span>
        <span v-else>Hostess</span>
      </template>
      <template #bottom-row="">
        <b-pagination
          v-model="currentPage"
          class="p-1 my-0"
          :total-rows="items.length"
          :per-page="perPage"
          aria-controls="tbl-privacy-log"
        />
      </template>
    </b-table>
    <div v-else>
      <timeline :items="items">
        <template #default="{ data: { item } }">
          <h5 class="fw-bold">
            {{ item.date ? $dateFormat(item.date, 'DD/MM/YYYY HH:mm') : '' }}
          </h5>
          <p class="text-muted mb-2 fw-bold">
            <span v-if="item.staffUser">
              <span v-if="item.staffUser.role !== ''" class="font-weight-bold">[{{ item.staffUser.role }}] </span>
              <span class="">{{ item.staffUser.email }}</span>
            </span>
            <span v-else>Hostess</span>
          </p>
          <p class="p-0 text-muted">
            <strong>{{ $t('fCustomer_privacyLog_f_privacy') }}</strong>: {{ item.value }}
          </p>
        </template>
      </timeline>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PrivacyLog',
  props: {
    itemId: {
      type: [String, Number],
      required: true
    },
    idx: {
      type: Number,
      default: -1
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      sortBy: 'date',
      sortDesc: true,
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: 'date',
          label: this.$t('fCustomer_privacyLog_f_date'),
          sortable: true,
          tdClass: 'col-2 text-left',
          formatter: (value, key, item) => {
            return item?.date
              ? this.$dateFormat(item.date, 'DD/MM/YYYY<br>HH:mm')
              : ''
          }
        },
        {
          key: 'staffUser',
          label: this.$t('fCustomer_privacyLog_f_staff_user'),
          sortable: true,
          formatter: (value) => {
            return value ? `${value.email}&nbsp;(Id:&nbsp;${value.id})` : 'Hostess'
          }
        },
        {
          key: 'value',
          label: this.$t('fCustomer_privacyLog_f_privacy'),
          tdClass: 'col-2 text-left'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      deviceInfo: 'device'
    }),
    isMobile () {
      return this.deviceInfo?.type === 'mobile' || false
    }
  },
  watch: {},
  async mounted () {
  },
  methods: {
    ...mapActions({})
  }
}
</script>

<style scoped>

</style>
