<template>
  <b-dropdown
    v-if="isVisible"
    :title="$t('fCustomer_actions_title')"
    right
    no-caret
    toggle-class="text-decoration-none"
    variant="link"
    size="lg"
  >
    <template #button-content>
      <font-awesome-icon icon="ellipsis-vertical" />
    </template>
    <component
      :is="option.type"
      v-for="option in options"
      :key="option.label"
      :variant="option.variant || 'secondary'"
      @click.stop.prevent="option.action"
    >
      <font-awesome-icon v-if="option.icon" :icon="option.icon" class="mr-2" />
      {{ option.label }}
    </component>
  </b-dropdown>
</template>

<script>
export default {
  name: 'ActionsDropdown',
  props: {
    actions: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    isVisible () {
      return this.actions.filter(i => !!i.enabled).length > 0
    },
    options () {
      const options = []
      for (const v of this.actions) {
        if (v.enabled) {
          switch (v.name) {
            case 'club-member-staff-enable':
              options.push({
                type: 'b-dropdown-item',
                label: this.$t('fCustomer_club_staff_add'),
                action: e => this.$emit('on-action', v.name),
                icon: 'users'
              })
              break
            case 'club-member-staff-disable':
              options.push({
                type: 'b-dropdown-item',
                label: this.$t('fCustomer_club_staff_delete'),
                action: e => this.$emit('on-action', v.name),
                variant: 'warning',
                icon: 'users-slash'
              })
              break
            case 'club-member-life-enable':
              options.push({
                type: 'b-dropdown-item',
                label: this.$t('fCustomer_club_life_add'),
                action: e => this.$emit('on-action', v.name),
                icon: 'users'
              })
              break
            case 'club-member-life-disable':
              options.push({
                type: 'b-dropdown-item',
                label: this.$t('fCustomer_club_life_delete'),
                action: e => this.$emit('on-action', v.name),
                variant: 'warning',
                icon: 'users-slash'
              })
              break
            case 'impersonate':
              options.push({
                type: 'b-dropdown-item',
                label: this.$t('fCustomer_imper_lock'),
                action: e => this.$emit('on-action', v.name),
                icon: 'user-lock'
              })
              break
            case 'impersonate-unlock':
              options.push({
                type: 'b-dropdown-item',
                label: this.$t('fCustomer_imper_unlock'),
                action: e => this.$emit('on-action', v.name),
                variant: 'info',
                icon: 'user-shield'
              })
              break
            case 'delete':
              options.push({
                type: 'b-dropdown-item',
                label: this.$t('fCustomer_Btn_delete'),
                action: e => this.$emit('on-action', v.name),
                variant: 'danger',
                icon: 'user-slash'
              })
              break
            case 'restore':
              options.push({
                type: 'b-dropdown-item',
                label: this.$t('fCustomer_Btn_restore'),
                action: e => this.$emit('on-action', v.name),
                variant: 'info',
                icon: 'user-check'
              })
              break
            case 'delete-permanent':
              options.push({
                type: 'b-dropdown-divider'
              })
              options.push({
                type: 'b-dropdown-item',
                label: this.$t('fCustomer_Btn_deletePermanent'),
                action: e => this.$emit('on-action', v.name),
                variant: 'danger',
                icon: 'user-xmark'
              })
              break
          }
        }
      }
      return options
    }
  }
}
</script>

<style scoped>

</style>
